import React from "react";
import { SectionHeader } from "../../../common/SectionHeader";
import { SectionContent } from "../../../common/SectionContent";
import { SubSectionHeader } from "../../../common/SubSectionHeader";
import { ImageContainer } from "../../../common/ImageContainer";
import GoToLink from "../../../common/GoToLink";

export const FormatViewSettings = () => {
  return (
    <React.Fragment>
      <SectionHeader title="FORMAT/VIEW SETTINGS">
        <SectionContent>
          <p>
            This section is likely designed to allow users to customize the
            format and view settings of the financial model or data within the
            application. Here, you may find options to adjust how data is
            displayed, set preferences for viewing reports, or customize the
            format of financial statements.
          </p>
          <p>
            Navigate to the "Create Model" section in the left navigation menu.
            Click on{" "}
            <GoToLink
              goToLink="/app/format-view-settings"
              goToText="Format/View Settings"
              linkOnly
            />{" "}
            to access this section.
          </p>
        </SectionContent>
      </SectionHeader>
      <SubSectionHeader title="FISCAL YEAR END">
        <SectionContent>
          <p>
            The company fiscal year, also known as the financial year or
            accounting year, is the 12-month period that a business or
            organization uses for financial reporting. The fiscal year used as
            the basis for annual budgeting and strategic planning. It provides a
            structured timeframe for setting financial goals and making business
            decisions.
          </p>
          <ImageContainer fileName="fiscalYearEnd.png" alt="Fiscal year end" />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="BILLINGS AND REVENUES DISPLAY OPTIONS">
        <SectionContent>
          <p>{`If you created categories for the billings/revenues, you have the option of displaying the totals only or displaying the totals and the individual products in each category. You also have the option to display billings or hide billings on the income statement projections.`}</p>
          <ImageContainer
            fileName="billingRevSettigns.png"
            alt="Billing and Revenue Settings"
          />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="DISPLAY OPTION FOR SALARIES & BENEFITS">
        <SectionContent>
          <p>{`This setting allows you to choose the granularity of Salaries and Benefits output. There are 3 options:`}</p>
          <ol type="1">
            <li>{`Display only total salaries & benefits row –A single line will be added in income statement which is summation of the lines under salaries & benefits`}</li>
            <li>{`Display salaries, benefits, and payroll taxes sub-total rows-  Subtotal lines for salaries,benefits and payroll taxes will be added in income statement`}</li>
            <li>{`Display line items details for each sub_total row selected(check if applicable) -  Role wise  numbers will be displayed in income statement`}</li>
          </ol>
          <ImageContainer
            fileName="salayBenefitsSettings.png"
            alt="Salary and Benefits Settings"
          />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="P&L PRESENTATION OPTIONS">
        <SectionContent>
          <p>{`Seqnc allows you to select either a line item or department view for your company’s P&L. A line-item view would display all costs and expenses that you added under Direct Costs and Operating Expenses. If you created categories in costs and expenses, the P&L will automatically display your costs and expenses by category; except that cost and expenses that are not categorized, if any, will be displayed as separate line items.`}</p>
          <ImageContainer fileName="categoryView.png" alt="Category View" />
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="PERIOD SUMMARY">
        <SectionContent>
          <p>{`Seqnc allows you to add quarter and annual period summaries in reports, where applicable. For example, if you want to add a quarterly total column in the P&L statement, you can do that by simply selecting the quarter options in the period summary settings. The quarter column will be computed automatically and added in the financial statement.`}</p>
          <ImageContainer fileName="periodSummary.png" alt="Period Summary" />
          <p>{`The period summary column will be based on the financial year end specified. The system allows you to set different period summaries for the first 12 months of the forecast and subsequent periods. For example, you can display quarterly summary for the first 12 months of a 36-month forecast, and only the annual column for subsequent years. Period summary columns are calculated based on the type of row being summarized. For example, the period summary for a P&L row is computed differently than that for a balance sheet row. Any period summary will also be included in the downloaded file.`}</p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="CURRENCY AND NUMBER FORMATS">
        <SectionContent>
          <ImageContainer fileName="currencyFormat.png" alt="Period Summary" />
          <p>
            Select preferred format settings for numbers, percentages, dates and
            currency in this section. These formats are respected in the summary
            tables, reports, and input spreadsheet
          </p>
        </SectionContent>
      </SubSectionHeader>
      <SubSectionHeader title="GROSS MARGIN DISPLAY OPTIONS">
      <SectionContent>
        <p>
            This setting allows you to choose the different options for displaying Gross Margin options
             in the income statement report. There are four options:
             <ol type="1">
            <li>{'Gross margin %'}</li>
            <li>{'Gross profit(loss), by product'}</li>
            <li>{'Gross margin, by product'}</li>
            <li>{'Operating income %'}</li>
            </ol>
        </p>
        <ImageContainer fileName="gross_margin_options.png" alt="Gross Margin" />
        <p>
          If user chooses Gross profit(loss), by product or Gross margin, by product, in income statement report
          all the products will be displayed under these options.
        </p>
          
      </SectionContent>
      </SubSectionHeader>
    </React.Fragment>
  );
};
